module.exports = [
  {
    key: "en-gb",
    path: "en",
    name: "English",
    default: true
  },
  {
    key: "th",
    path: "th",
    name: "Thai"
  },
  {
    key: "id",
    path: "id",
    name: "Bahasa Indonesia"
  }
];
