const colors = {
  white: "#FFFFFF",
  black: "#595959",
  green: "#00CA7F",
  yellow: "#FFC400",
  orange: "#FF8474",
  red: "#FF5251",
  // blue
  brandBlue: "#1D2540",
  blue: "#3B86FF",
  aliceBlue: "#F6FBFF",
  darkBlue: "#222B47",
  cyanBlue: "#42526E",
  // grey
  grey: "#CBCBCB",
  lightGrey: "#EFEDE8",
  darkGrey: "#A0A0A0",
  greyBlue: "#E1E5EE",
  blueGrey: "#737E92",
  border: "#D9D9D9",
  whiteSmoke: "#F2F2F2",
  ghostWhite: "#F8F9FA",
  disabled: "#BFBFBF",
  background: "#FAFAFA",
  gray6: "#BFBFBF",
  gray4: "#F0F0F0",
  gray3: "#F5F5F5",
  caption: "#8C8C8C",
  shadow: "#EDF0F7",
  divider: "#F2F3F3",
  // text
  title: "#262626",
  body: "#595959",
  link: "#1890FF",
};

export default colors;
