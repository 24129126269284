const routes = {
  home: "/",
  business: "/business",
  workers: "/workers",
  jobDetail: "/app/jobs/:id",
  about: "/about",
  careers: "/careers",
  press: "/press",
  howItWorks: "/how-it-works",
  blog: "/blog",
  eBooks: "/ebooks-and-guides",
  features: "/features",
  industry: "/industries",
  pricing: "/pricing",
  // Standard Pages
  privacyPolicy: "/privacy-policy",
  workerTerms: "/worker-terms",
};

export default routes;
