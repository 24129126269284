import locales from '../locales';

export const getUrlForLocale = (url, locale) => {
  return locale.default ? url : url.replace('/', `/${locale.path}/`);
};

export const getLocaleByKey = localeKey => {
  return locales.filter(locale => locale.key === localeKey)[0];
};

export const getLocaleFromUrl = (url) => {
  // We need to check the current locale by looking at the URL
  let currLocale = locales[0];
  locales.forEach(locale => {
    if (url.slice(0, 4) === `/${locale.path}/`) {
      currLocale = locale;
    }
  });
  return currLocale;
};