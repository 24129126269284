import axios from "axios";

export const environment = {
  STAGING: "staging",
  PRODUCTION: "production",
  LOCAL: "local",
};

export const getEnvironment = () => {
  const hostname =
    typeof window !== "undefined" &&
    window &&
    window.location &&
    window.location.hostname;
  if (hostname === "www.workmate.asia") {
    return environment.PRODUCTION;
  } else if (hostname === "www.helpster.tech") {
    return environment.STAGING;
  } else {
    return environment.LOCAL;
  }
};

export function handleResponseAxios(response) {
  console.log(response.data);
  return response.data;
}

export const getClientIpAddress = async () => {
  return (await fetch(`https://api.ipify.org`)).text();
};

export const getGeoInfoFromIp = async () => {
  return (await axios.get("https://ipapi.co/json/"))?.data;
};
